import React from "react";
import OpenHours from "./OpenHours";

export default function Sprechstunden() {
    return(
        <div className="container mx-auto px-8 py-8 xl:px-16">
            <div className="card bg-primary/50 shadow-2xl hover:shadow-2xl">
                <div className="card-body">
                    <div>
                        <h1 className="card-title">Hinweis</h1>
                        <p>
                            Bitte beachten Sie unsere Öffnungszeiten unter Vorbehalt der unten beiliegenden Praxisurlaube.
                        </p>
                    </div>
                </div>
            </div>

            <div className="py-8"></div>
            <div className="flex flex-col lg:flex-row">
                <div>
                    <OpenHours/>
                </div>
                <div className="lg:px-6"></div>

                <div className="flex flex-col">
                    <Urlaub25/>
                </div>

            </div>
            <div className="flex flex-col lg:flex-row leading-loose w-full py-8">

            </div>

        </div>
    );
}

/*function Urlaub23() {
    return (
        <div className="max-lg:py-16">
            <div className="card rounded-lg bg-base-200 shadow-2xl">
                <div className="px-10 max-lg:py-8">
                    <div className="lg:py-4"></div>
                    <div className="text-xl lg:text-2xl font-bold py-2">
                        Geplanter Urlaub 2023
                    </div>
                    <div className="flex flex-col lg:flex-row leading-loose w-full py-4">
                        <div className="lg:py-8 grid grid-cols-2 xl:gap-x-20 max-md:gap-x-5 gap-x-0 ">
                            <div className="font-bold">13.02. - 17.02.</div> <div>Urlaub</div>
                            <div className="font-bold">31.03.</div> <div >Abrechnungstag</div>
                            <div className="font-bold">11.04. - 14.04.</div> <div>Osterwoche</div>
                            <div className="font-bold">15.05. - 26.05.</div> <div>Pfingsten</div>
                            <div className="font-bold">09.06.</div> <div>Brückentag</div>
                            <div className="font-bold">30.06.</div> <div>Abrechnungstag</div>
                        </div>
                        <div className="divider divider-horizontal lg:px-2"></div>
                        <div className="lg:py-8 grid grid-cols-2 xl:gap-x-20 max-md:gap-x-5 gap-x-0  ">
                            <div className="font-bold">31.07. - 18.08.</div> <div>Sommerferien</div>
                            <div className="font-bold">29.09.</div> <div>Abrechnungstag</div>
                            <div className="font-bold">02.10.</div> <div>Brückentag</div>
                            <div className="font-bold">23.10. - 27.10.</div> <div>Urlaub</div>
                            <div className="font-bold">22.12.</div> <div>Abrechnungstag</div>
                            <div className="font-bold">27.12. - 29.12.</div> <div>Feiertage</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}*/
/*
function Urlaub24() {
    return (
        <div className="max-lg:py-8">
            <div className="card rounded-lg bg-base-200 shadow-2xl">
                <div className="px-10 max-lg:py-8">
                    <div className="lg:py-4"></div>
                    <div className="text-xl lg:text-2xl font-bold py-2">
                        Geplanter Urlaub 2024
                    </div>
                    <div className="flex flex-col lg:flex-row leading-loose lg:mb-4 w-full py-4">
                        <div className="lg:py-8 grid grid-cols-2 xl:gap-x-20 max-md:gap-x-5 gap-x-0 ">
                            <div className="font-bold">12.02. - 16.02.</div> <div>Urlaub</div>
                            <div className="font-bold">28.03.</div> <div >Abrechnungstag</div>
                            <div className="font-bold">08.04. - 12.04.</div> <div>Osterferien</div>
                            <div className="font-bold">10.05.</div> <div>Brückentag</div>
                            <div className="font-bold">21.05. - 31.05.</div> <div>Pfingsten</div>
                            <div className="font-bold">28.06.</div> <div>Abrechnungstag</div>
                        </div>
                        <div className="divider divider-horizontal lg:px-2"></div>
                        <div className="lg:py-8 grid grid-cols-2 xl:gap-x-20 max-md:gap-x-5 gap-x-0  ">
                            <div className="font-bold">05.08. - 23.08.</div> <div>Sommerferien</div>
                            <div className="font-bold">30.09.</div> <div>Abrechnungstag</div>
                            <div className="font-bold">04.10.</div> <div>Brückentag</div>
                            <div className="font-bold">14.10. - 18.10.</div> <div>Urlaub</div>
                            <div className="font-bold">20.12.</div> <div>Abrechnungstag</div>
                            <div className="font-bold">23.12. - 31.12.</div> <div>Winterferien</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}*/

function Urlaub25() {
    return (
        <div className="max-lg:py-8 lg:mt-10">
            <div className="card rounded-lg bg-base-200 shadow-2xl">
                <div className="px-10 max-lg:py-8">
                    <div className="lg:py-4"></div>
                    <div className="text-xl lg:text-2xl font-bold py-2">
                        Geplanter Urlaub 2025
                    </div>
                    <div className="flex flex-col lg:flex-row leading-loose lg:mb-4 w-full py-4">
                        <div className="lg:py-8 grid grid-cols-2 xl:gap-x-20 max-md:gap-x-5 gap-x-0 ">
                            <div className="font-bold">10.02. - 14.02.</div> <div>Urlaub</div>
                            <div className="font-bold">31.03.</div> <div className="lg:mr-14 max-xl:mr-10 xl:mr-12">Abrechnungstag</div>
                            <div className="font-bold">14.04. - 17.04.</div> <div>Urlaub</div>
                            <div className="font-bold">19.05. - 30.05.</div> <div>Urlaub</div>
                            <div className="font-bold">20.06.</div> <div>Brückentag</div>
                            <div className="font-bold">30.06.</div> <div>Abrechnungstag</div>
                        </div>
                        <div className="divider divider-horizontal lg:px-2"></div>
                        <div className="lg:py-8 grid grid-cols-2 xl:gap-x-8 lg:gap-x-4 max-md:gap-x-4 gap-x-0  ">
                            <div className="font-bold">28.07. - 15.08.</div> <div>Sommerferien</div>
                            <div className="font-bold">30.09.</div> <div>Abrechnungstag</div>
                            <div className="font-bold">13.10. - 17.10.</div> <div>Urlaub</div>
                            <div className="font-bold">22.12.</div> <div>Abrechnungstag</div>
                            <div className="font-bold">22.12.2025 - 02.01.2026</div> <div>Feiertage</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}